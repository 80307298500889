
import { Options, Vue, setup } from "vue-class-component";
import { NavBar, Button, CellGroup, Field, Toast, Switch, Tag } from "vant";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import { appKey } from "@/const";
import { AllState } from "@/store";
import { getQueryParams } from "@/utils/index";
@Options({
  components: {
    NavBar,
    Button,
    CellGroup,
    Field,
    Switch,
    Tag
  }
})
export default class Setting extends Vue {
  setting = setup(() => {
    const store = useStore<AllState>();
    let userStr = ref("");
    let userID = ref("");
    let roomId = ref("");
    let delUserID = ref("");
    let blockStr = ref("");
    let blockId = ref("");
    let delBlockId = ref("");
    let groupId = ref("");
    let infoGroupId = ref("");
    let appkey = ref(appKey);
    let remark = ref("");
    let allContacts = ref("");
    let cursor = ref("");
    let pageSize = ref(undefined);
    let isMarked = ref(true);
    let conversationId = ref("");
    let conversationType = ref("");
    let mark = ref("");
    let filterConversations = ref("");
    let cursorContacts = ref("");
    let presenceDesc = ref("");
    let chatRoomId = ref("");
    let expiry = ref(0);
    let joinedChatRoomsParams = ref("pageNum=1&pageSize=20");

    const getAllContacts = () => {
      store.state.IM.connect
        .getAllContacts()
        .then((res: any) => {
          allContacts.value = JSON.stringify(res.data);
          console.log("getAllContacts成功", res);
        })
        .catch((e: any) => {
          console.log("getAllContacts失败", e);
        });
    };

    const getContactsWithCursor = () => {
      store.state.IM.connect
        .getContactsWithCursor({
          cursor: cursor.value,
          pageSize: isNaN(Number(pageSize.value))
            ? pageSize.value
            : Number(pageSize.value)
        })
        .then((res: any) => {
          cursorContacts.value = JSON.stringify(res.data);
          console.log("getContactsWithCursor成功", res);
        })
        .catch((e: any) => {
          console.log("getContactsWithCursor失败", e);
        });
    };

    const getBlockList = () => {
      store.state.IM.connect.getBlacklist().then((res: any) => {
        blockStr.value = res.data.join("、");
      });
    };

    const addFriend = () => {
      let msg = "添加个好友吧!";
      store.state.IM.connect
        .addContact(userID.value, msg)
        .then(() => {
          Toast("发送好友请求成功");
        })
        .catch((err) => {
          console.log("发送好友请求失败", err);
          Toast("发送好友请求失败");
        });
    };

    const addBlock = () => {
      const names = blockId.value.split(",");

      store.state.IM.connect
        .addUsersToBlocklist({
          name: names?.length === 1 ? names[0] : names
        })
        .then((res) => {
          //@ts-ignore
          console.log(res, "addUsersToBlocklist res");
          Toast(`将${blockId.value}加入黑名单成功`);
        })
        .catch((e) => {
          console.log(`将${blockId.value}加入黑名单失败`, e);
          Toast(`将${blockId.value}加入黑名单失败`);
        });
    };

    const delFriend = () => {
      store.state.IM.connect
        .deleteContact(delUserID.value)
        .then(() => {
          console.log("删除好友成功");
          Toast("删除好友成功");
        })
        .catch((e) => {
          console.log("删除好友失败", e);
          Toast(`删除好友失败`);
        });
    };

    const delBlock = () => {
      const names = delBlockId.value.split(",");
      store.state.IM.connect
        .removeUserFromBlocklist({
          name: names?.length === 1 ? names[0] : names
        })
        .then(() => {
          Toast(`将${delBlockId.value}移除黑名单成功`);
        })
        .catch((e) => {
          console.log("移除黑名单失败", e);
          Toast(`将${delBlockId.value}移除黑名单失败`);
        });
    };

    // 加入群组
    const joinGroup = () => {
      store.state.IM.connect
        .joinGroup({
          groupId: groupId.value,
          message: "申请加入群组"
        })
        .then(() => {
          Toast("申请加入群组成功");
        })
        .catch(() => {
          Toast("申请加入群组失败");
        });
    };

    // 获取群组信息,支持批量和获取单个
    const getGroupInfo = () => {
      let dt: string | string[] = infoGroupId.value.split(",");
      if (dt.length === 1) {
        dt = dt[0];
      }
      store.state.IM.connect
        .getGroupInfo({
          groupId: dt
        })
        .then((res) => {
          console.log(res, "res");
        })
        .catch((e) => {
          console.log(e, "eee");
        });
    };

    const setRemark = () => {
      store.state.IM.connect
        .setContactRemark({
          userId: userID.value,
          remark: remark.value
        })
        .then(() => {
          console.log("设置备注成功");
        })
        .catch((e: unknown) => {
          console.log(e, "设置备注失败");
        });
    };

    const joinRoom = () => {
      store.state.IM.connect
        .joinChatRoom({
          roomId: roomId.value
        })
        .then(() => {
          console.log("加入聊天室成功");
        })
        .catch((e: unknown) => {
          console.log(e, "加入聊天室失败");
        });
    };

    const leaveRoom = () => {
      store.state.IM.connect
        .leaveChatRoom({
          roomId: roomId.value
        })
        .then(() => {
          console.log("离开聊天室成功");
        })
        .catch((e: unknown) => {
          console.log(e, "离开聊天室失败");
        });
    };

    const markConversation = () => {
      const conversationIds = conversationId.value.split(",");
      const conversationTypes = conversationType.value.split(",");
      const conversations = conversationIds.map((conversationId, index) => {
        return {
          conversationId,
          conversationType: conversationTypes[index] as
            | "singleChat"
            | "groupChat"
        };
      });
      if (isMarked.value) {
        store.state.IM.connect
          .addConversationMark({
            conversations,
            mark: Number(mark.value)
          })
          .then(() => {
            console.log("标记成功");
          })
          .catch((e: unknown) => {
            console.log(e, "标记失败");
          });
      } else {
        store.state.IM.connect
          .removeConversationMark({
            conversations,
            mark: Number(mark.value)
          })
          .then(() => {
            console.log("取消标记成功");
          })
          .catch((e: unknown) => {
            console.log(e, "取消标记失败");
          });
      }
    };

    const getServerConversationsByFilter = () => {
      store.state.IM.connect
        .getServerConversationsByFilter({
          pageSize: Number(pageSize.value || 0),
          cursor: cursor.value,
          filter: {
            mark: Number(mark.value)
          }
        })
        .then((res: any) => {
          console.log("根据mark获取会话标记成功", res);
        })
        .catch((e: unknown) => {
          console.log("根据mark获取会话标记失败", e);
        });
    };

    const getServerConversations = () => {
      store.state.IM.connect
        .getServerConversations({
          pageSize: Number(pageSize.value || 0),
          cursor: cursor.value
        })
        .then((res: any) => {
          console.log("获取服务端会话列表成功", res);
        })
        .catch((e: unknown) => {
          console.log("获取服务端会话列表失败", e);
        });
    };

    const getServerPinnedConversations = () => {
      store.state.IM.connect
        .getServerPinnedConversations({
          pageSize: Number(pageSize.value || 0),
          cursor: cursor.value
        })
        .then((res: any) => {
          console.log("获取服务端置顶会话列表成功", res);
        })
        .catch((e: unknown) => {
          console.log("获取服务端置顶会话列表失败", e);
        });
    };

    const publishPresence = () => {
      store.state.IM.connect
        .publishPresence({
          description: presenceDesc.value
        })
        .then((res: any) => {
          console.log("发布在线状态成功", res);
        })
        .catch((e: unknown) => {
          console.log("发布在线状态失败", e);
        });
    };

    const subscribePresence = () => {
      store.state.IM.connect
        .subscribePresence({
          usernames: [userID.value],
          expiry: Number(expiry.value)
        })
        .then((res: any) => {
          console.log("订阅在线状态成功", res);
        })
        .catch((e: unknown) => {
          console.log("订阅在线状态失败", e);
        });
    };

    const unsubscribePresence = () => {
      store.state.IM.connect
        .unsubscribePresence({
          usernames: [userID.value]
        })
        .then((res: any) => {
          console.log("取消订阅在线状态成功", res);
        })
        .catch((e: unknown) => {
          console.log("取消订阅在线状态失败", e);
        });
    };

    const getSubscribedPresenceList = () => {
      store.state.IM.connect
        .getSubscribedPresencelist({
          pageNum: 1,
          pageSize: Number(pageSize.value || 0)
        })
        .then((res: any) => {
          console.log("获取订阅用户在线状态成功", res);
        })
        .catch((e: unknown) => {
          console.log("获取订阅用户在线状态失败", e);
        });
    };

    const getPresenceStatus = () => {
      store.state.IM.connect
        .getPresenceStatus({
          usernames: [userID.value]
        })
        .then((res: any) => {
          console.log("获取用户在线状态成功", res);
        })
        .catch((e: unknown) => {
          console.log("获取用户在线状态失败", e);
        });
    };

    const getJoinedChatRooms = () => {
      const obj: any = getQueryParams(joinedChatRoomsParams.value);
      console.log(obj, "params obj");
      store.state.IM.connect
        .getJoinedChatRooms({
          pageNum: Number(obj.pageNum),
          pageSize: Number(obj.pageSize)
        })
        .then((res: any) => {
          console.log("获取当前用户加入的聊天室成功", res);
        })
        .catch((e: unknown) => {
          console.log("获取当前用户加入的聊天室失败", e);
        });
    };

    const destroyChatRoom = () => {
      // 解散聊天室
      store.state.IM.connect
        .destroyChatRoom({
          chatRoomId: chatRoomId.value,
          token: ""
        })
        .then((res: any) => {
          console.log("解散聊天室成功", res);
        })
        .catch((e: unknown) => {
          console.log("解散聊天室失败", e);
        });
    };

    onMounted(() => {
      // getAllContacts();
    });

    return {
      userStr,
      userID,
      delUserID,
      blockStr,
      blockId,
      roomId,
      delBlockId,
      appkey,
      groupId,
      infoGroupId,
      remark,
      allContacts,
      cursor,
      pageSize,
      isMarked,
      conversationId,
      conversationType,
      mark,
      filterConversations,
      cursorContacts,
      presenceDesc,
      expiry,
      joinedChatRoomsParams,
      chatRoomId,
      getAllContacts,
      getContactsWithCursor,
      addFriend,
      delFriend,
      getBlockList,
      addBlock,
      delBlock,
      joinGroup,
      getGroupInfo,
      setRemark,
      joinRoom,
      leaveRoom,
      markConversation,
      getServerConversationsByFilter,
      getServerConversations,
      getServerPinnedConversations,
      publishPresence,
      subscribePresence,
      unsubscribePresence,
      getSubscribedPresenceList,
      getPresenceStatus,
      getJoinedChatRooms,
      destroyChatRoom
    };
  });
}
